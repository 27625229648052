import React, {useState, useEffect} from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { 
    AppBar,
    Toolbar,
    Drawer,
    IconButton,
    Button,
    Divider,
    Box,
    Grid,
    Typography,
    TextField,
    Popover,
    Backdrop,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Snackbar,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    Paper,
    Popper
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import logo from './canvas_image/Mushroom-X Logo FINAL (Black_CMYK).png';
import clsx from 'clsx';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import BackupOutlinedIcon from '@material-ui/icons/BackupOutlined';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import EditIcon from '@material-ui/icons/Edit';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import PictureAsPdfOutlinedIcon from '@material-ui/icons/PictureAsPdfOutlined';
import ColorizeIcon from '@material-ui/icons/Colorize';
import ColorLensOutlinedIcon from '@material-ui/icons/ColorLensOutlined';
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import OpacityIcon from '@material-ui/icons/Opacity';
import SendIcon from '@material-ui/icons/Send';
import CloseIcon from '@material-ui/icons/Close';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import UndoIcon from '@material-ui/icons/Undo';
import RedoIcon from '@material-ui/icons/Redo';
import FlipToFrontIcon from '@material-ui/icons/FlipToFront';
import FlipToBackIcon from '@material-ui/icons/FlipToBack';
import SelectAllIcon from '@material-ui/icons/SelectAll';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import { fabric } from 'fabric-with-gestures';
import {SCALE_FACTOR, UPLOAD_IMAGE_PATH, PRESET_IMAGE_PATH, PUBLIC_URL} from "./Config";
import {SERVER_URL,} from './Domian';
import axios from "axios";
import {downloadPNG, downloadPDF} from './Download';
import {PhotoshopPicker, ChromePicker, SketchPicker} from 'react-color';
import Mousetrap from 'mousetrap';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import {
    usePopupState,
    bindToggle,
    bindPopper,
} from 'material-ui-popup-state/hooks';
import Fade from '@material-ui/core/Fade';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTranslation } from 'react-i18next';
import SwitchLanguageButton from './SwitchLanguageButton';
import moment from 'moment';

const drawerWidth = 400;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height: '100%',
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: "#BECD70"
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginRight: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: 'flex-start',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginRight: -drawerWidth,
        display: 'flex',
        flexDirection: 'column'
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
    span:{
        flexGrow: 1,
    },
    langSwitch: {
        marginLeft: 5,
        marginRight: 5
    },
    logo: {
        maxWidth: '100px'
    },
    button: {
        fontFamily: "MyriadProBold",
        fontSize:12,
        backgroundColor: "#fae232",
        '&:hover': {
            background: "#006699",
            color: "white"
        },
        color: "black",
        marginLeft: 8,
        marginRight: 8,
        marginTop: 1
    },
    buttonBig: {
        fontFamily: "MyriadProBold",
        fontSize:12,
        backgroundColor: "#fae232",
        '&:hover': {
            background: "#006699",
            color: "white"
        },
        color: "black",
        marginLeft: 8,
        marginRight: 8,
        marginTop: 1
    },
    canvasViewport: {
        flexGrow: 1, 
    },
    menuImage:{
        width: '100%'
    },
    menuImageWrapper: {
        cursor: 'pointer',
        position: 'relative'
    },
    imageSelected: {
        background: theme.palette.primary.main
    },
    menuImageButton: {
        position: 'absolute',
        right: 0,
        color: '#000000'
    },
    menuImagePopover: {
        width: 300,
        minHeight: 200
    },
    menuImagePopoverImage: {
        width: '100%'
    },
    backdrop:{
        zIndex: 1200
    },
    backdropDialogNameField:{
        width: '100%',
        '& label.Mui-focused': {
            color: '#006699',
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
            },
            '&:hover fieldset': {
            },
            '&.Mui-focused fieldset': {
              borderColor: '#006699',
            },
          },
    },
    radioForm: {
        marginTop: 30,
    },
    radio:{
        fontFamily: "MyriadProBold",
        '&$checked': {
            color: '#006699'
          }
    },
    checked:{

    },
    logout:{
        color: "black"
    },
    title:{
        fontFamily: "MyriadProBold",
        backgroundColor:"#fae232",
        height: 35,
        borderRadius: 5,
    },
    box:{
        paddingRight: 16
    },
    name:{
        fontFamily: "MyriadProBold",
    },
    popper:{
        zIndex: 10000,
    },
    popperPaper:{
        padding: 20
    },
    typography:{
        fontFamily: "MyriadProBold", 
    }
}));

const UploadedImage = (props) => {
    const classes = useStyles();
    const {image, onDragStart, onClick} = props;

    const handleClick = (e) => {
        onClick(image, `${UPLOAD_IMAGE_PATH}/${image}`);
    }

    return (
        <img onContextMenu={(e) => {e.preventDefault()}} className={classes.menuImage} src={`${UPLOAD_IMAGE_PATH}/${image}`} alt={image} draggable="true" onDragStart={onDragStart} onClick={handleClick}/>
    );
}

const PresetImage = (props) => {
    const classes = useStyles();
    const {imageSet, imageBaseName, Drag, Selected} = props;
    const [coverImageUrl, setCoverImageUrl] = useState(`${PRESET_IMAGE_PATH}/${imageBaseName}/${imageSet[0]}`);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleColorClicked = (e) => {
        setCoverImageUrl(e.currentTarget.src);
        Selected(imageBaseName, e.currentTarget.src);
        handleClose();
    }

    const handleCoverImageClick = (e) => {
        Selected(imageBaseName, coverImageUrl)
    }

    const open = Boolean(anchorEl);
    const id = open ? `simple-popover-${imageBaseName}` : undefined;

    return (
        <div>
            <img onContextMenu={(e) => {e.preventDefault()}} className={classes.menuImage} src={coverImageUrl} alt={imageSet[0]} draggable="true" onDragStart={Drag} onClick={handleCoverImageClick}/>
            <IconButton aria-describedby={id} className={classes.menuImageButton} onClick={handleClick}>
                <OpacityIcon/>
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            >
                <Grid container spacing={0} className={classes.menuImagePopover}>
                    {
                        (imageSet || [] ).map((image, index) => (
                            <Grid item xs={4} key={index} className={classes.menuImageWrapper}>
                                <img className={classes.menuImagePopoverImage} src={`${PRESET_IMAGE_PATH}/${imageBaseName}/${image}`} alt={image} 
                                onClick={handleColorClicked}/>
                            </Grid>
                        ))
                    }
                </Grid>
            </Popover>
        </div>
    )
}

var canvas;
var GlobalRatio = 3580/2480;
var clipboard = null;
var history = [];
var currState = -1;
var offsetLeft = 0;

const CanvasPage = () => {

    const classes = useStyles();
    const theme = useTheme();
    const roomKey = localStorage.getItem("login_key");
    const {t} = useTranslation();

    const [toolBoxOpen, setToolBoxOpen] = useState(true);
    const [backgroundColor, setBackgroundColor] = useState("#ffffff");
    const [color, setColor] = useState("#ffffff");
    const [presetImage, setPresetImage] = useState({});
    const [uploadImage, setUploadImage] = useState([]);
    const [text, setText] = useState('');
    const [userName, setUserName] = useState('');
    const [userNameError, setUserNameError] = useState('');

    const [canvasSize, setCanvasSize] = useState(3580/2480);

    const [backDropOpen, setBackDropOpen] = useState(true);
    const [infoBoxButton, setInfoBoxButton] = useState(true);

    const [selectedImage, setSelectedImage] = useState(null);
    const [simple, setSimple] = useState(true);

    const [anchorBackgroundPicker, setAnchorBackgroundPicker] = React.useState(null);
    const [anchorColorPicker, setAnchorColorPicker] = React.useState(null);

    const [snackBarOpen, setSnackBarOpen] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState("");

    const [printMode, setPrintMode] = useState(true);

    const [isUploading, setIsUploading] = useState(false);

    const [roomInfo, setRoomInfo] = useState(null);

    const expireAt = roomInfo ? (roomInfo.dynamic ? moment(roomInfo.activeAt).add(roomInfo.dayToExpire, 'days').format("YYYY-MM-DD (hh:mm A)") : roomInfo.expireAt) : "" ;

    var mouseDownPoint = {}
    useEffect(() => {
        const fetchpresetImage = async() => {
            const res = await axios.get(`${SERVER_URL}/api/presetImage`);
            setPresetImage(res.data);
        }

        const fetchUploadImage = async() => {
            const res = await axios.get(`${SERVER_URL}/api/image`);
            setUploadImage(res.data);
        }

        const fetchRoomMode = async() => {
            const res = await axios.get(`${SERVER_URL}/api/roomMode/${roomKey}`);
            setSimple(res.data);
        }

        const fetchRoomInformation = async () => {
            const loginKey = localStorage.getItem("login_key");
            const res = await axios.get(`${SERVER_URL}/api/key/${loginKey}`);
            setRoomInfo(res.data);
        }


        fetchpresetImage();
        fetchUploadImage();
        fetchRoomMode();
        fetchRoomInformation();
        currState = 0;

        Mousetrap.bind(['ctrl+c', 'command+c'], Copy);
        Mousetrap.bind(['ctrl+v', 'command+v'], Paste);
        Mousetrap.bind(['ctrl+z', 'command+z'], Undo);
        Mousetrap.bind(['ctrl+shift+z', 'command+shift+z'], Redo);
        Mousetrap.bind(['backspace', 'del'], Delete);
        Mousetrap.bind('up', MoveUp);
        Mousetrap.bind('down', MoveDown);
        Mousetrap.bind('esc', ResetAllSelecting);
    }, []);

    const ComputeCanvasDimension = (toolBoxOpen) => { 
        var ratio = GlobalRatio;       
        var root = document.getElementById('root');
        var main = document.getElementById('canvas-viewport').parentNode;
        var header_block = document.getElementById("main-header-block");
        var style = getComputedStyle(main);

        const maxWidth = (toolBoxOpen ? -drawerWidth : 0) + root.offsetWidth - parseInt(style.paddingLeft) - parseInt(style.paddingRight);
        const maxHeight = root.offsetHeight - parseInt(style.paddingTop) - parseInt(style.paddingBottom) - header_block.offsetHeight;

        let width = maxWidth;
        let height = width / ratio;
        if (height > maxHeight){
            height = maxHeight;
            width = height*ratio;
        }
    
        return [height, width];
    }

    const PostCanvasLoaded = () => {

        canvas.on('selection:created', (o) => {
            var activeObj = o.target;
            if(activeObj._objects) {
                activeObj.set({
                    cornerSize: 10 * SCALE_FACTOR,
                    borderColor: 'black',
                    borderWidth: 20 * SCALE_FACTOR,
                    cornerColor: 'black',
                    transparentCorners: false
                });
        
            }
        });

        canvas.on({
            'object:moved': Record,
            'object:rotated': Record,
            'object:scaled': Record,
        });

        history = [canvas.toJSON()];
        window.addEventListener("resize", ()=> {ResizeCanvasSize(toolBoxOpen)});
        setInterval(AutoUpload, 60000);
    }

    const ResizeCanvasSize = (toolBoxOpen) => {
        const [height, width] = ComputeCanvasDimension(toolBoxOpen);
        canvas.setHeight(height*SCALE_FACTOR);
        canvas.setWidth(width*SCALE_FACTOR);
    }

    const initCanvas = () => {
        const [height, width] = ComputeCanvasDimension(toolBoxOpen);
        var root = document.getElementById('root');
        var main = document.getElementById('canvas-viewport');
        var style = getComputedStyle(main.parentNode);

        canvas = new fabric.Canvas("main-canvas", {
            backgroundColor: backgroundColor,
            preserveObjectStacking: true,
            hoverCursor: "pointer",
            imageSmoothingEnabled: false,
	        enableRetinaScaling: false,
            height: height*SCALE_FACTOR,
            width: width*SCALE_FACTOR,
        });
        offsetLeft = ((root.offsetWidth - drawerWidth - width)*SCALE_FACTOR/2) - parseInt(style.paddingLeft);
        main.style.transform = `scale(${1/SCALE_FACTOR}) translateX(${offsetLeft}px)`;
        main.style.transformOrigin = `0px 0px`;
        

        main.parentNode.style.width = main.parentNode.offsetWidth / SCALE_FACTOR + "px";
        canvas.renderAll();

        PostCanvasLoaded();
    }

    const loadCanvas = (url) => {
        setIsUploading(true);
        const [height, width] = ComputeCanvasDimension(toolBoxOpen);
        var original_height = url.height;
        var original_width = url.width;
        var scaleRatio = height/original_height;
        var root = document.getElementById('root');
        var main = document.getElementById('canvas-viewport');
        var style = getComputedStyle(main.parentNode);
        var header_block = document.getElementById("main-header-block");
        
        canvas = new fabric.Canvas("main-canvas");
        canvas.loadFromJSON(url, () => {
            canvas.setHeight(height*SCALE_FACTOR);
            canvas.setWidth(width*SCALE_FACTOR);
            canvas.preserveObjectStacking = true;
            canvas.renderAll();
            canvas.getObjects().forEach((item, i) => {
                item.set({
                    cornerSize: 10 * SCALE_FACTOR,
                    borderColor: 'black',
                    borderWidth: 20 * SCALE_FACTOR,
                    cornerColor: 'black',
                    transparentCorners: false,
                });
            });
            setIsUploading(false);
        });
        offsetLeft = ((root.offsetWidth - drawerWidth - width)*SCALE_FACTOR/2) - parseInt(style.paddingLeft);
        main.style.transform = `scale(${1/SCALE_FACTOR}) translateX(${offsetLeft}px)`;
        main.style.transformOrigin = `0px 0px`;
        main.parentNode.style.width = main.parentNode.offsetWidth / SCALE_FACTOR + "px";

        PostCanvasLoaded();
    }

    const Logout = () => {
        localStorage.clear();
        window.location.href = "/login";
    }

    const handleToolBoxOpen = () => {
        setToolBoxOpen((state) => {
            ResizeCanvasSize(!state);
            return !state
        });
    };

    const handleToolBoxClose = () => {
        setToolBoxOpen((state) => {
            ResizeCanvasSize(!state);
            return !state
        });
    };

    const Record = () => {
        history = history.slice(0, currState + 1).concat(canvas.toJSON());
        currState = currState+1;
    }

    const Drag = (e) => {
        e.dataTransfer.setData("drag_drop_text", e.target.src);
    }

    const Drop = (e) => {
        if (e.dataTransfer.getData("drag_drop_text")){
            const dropX = (e.nativeEvent.offsetX || (e.nativeEvent.changedTouches[0].pageX - e.nativeEvent.target.offsetParent.offsetParent.offsetLeft) * SCALE_FACTOR);
            const dropY = (e.nativeEvent.offsetY || (e.nativeEvent.changedTouches[0].pageY - e.nativeEvent.target.offsetParent.offsetParent.offsetTop) * SCALE_FACTOR);
            AddIcon(dropX, dropY, e.dataTransfer.getData("drag_drop_text"));
        }
    }

    const AddIcon = (dropX, dropY, icon_url) => {
        if (!printMode){
            return;
        }
        var needColorPicker = true;
        fabric.Image.fromURL(icon_url, (oImg) => {

            var scaleRatio = (150/oImg.width) * SCALE_FACTOR;

            oImg.scale(scaleRatio);
            oImg.set({
                left: dropX - (oImg.width * scaleRatio * 0.5),
                top: dropY - (oImg.height * scaleRatio * 0.5),
                needColorPicker: needColorPicker,
            });

            if(!oImg.preset){
                setColorFilter(oImg);
            }
            AddItem(oImg);
        }, {crossOrigin: 'anonymous'});
    }

    const AddText = (e) => {
        if (!printMode){
            return;
        }
        e.preventDefault();
        var textObj = new fabric.IText(text, {
            left: 10 * SCALE_FACTOR, 
            top: 10 * SCALE_FACTOR, 
            fontSize: 100 * SCALE_FACTOR,
            needColorPicker: true,
        });
        AddItem(textObj);
    }

    const AddItem = (item) => {
        item.set({
            cornerSize: 10 * SCALE_FACTOR,
            borderColor: 'black',
            borderWidth: 20 * SCALE_FACTOR,
            cornerColor: 'black',
            transparentCorners: false
        });

        canvas.add(item);
        canvas.setActiveObject(item);
        canvas.renderAll();
        Record();
    }

    const Canvas_Mouse_Down=(e)=>{
        const dropX = (e.nativeEvent.offsetX || (e.nativeEvent.changedTouches[0].pageX * SCALE_FACTOR) - offsetLeft);
        const dropY = (e.nativeEvent.offsetY || (e.nativeEvent.changedTouches[0].pageY - e.nativeEvent.changedTouches[0].target.offsetParent.offsetParent.offsetTop) * SCALE_FACTOR);

        let activeObject = canvas.getActiveObject();
        if (activeObject != null){
            mouseDownPoint = {dropX: dropX, dropY: dropY, clickedObj: true};
        }else{
            mouseDownPoint = {dropX: dropX, dropY: dropY, clickedObj: false};
        }
        
    }

    const Canvas_Mouse_Up = (e) => {
        e.preventDefault();
        const dropX = (e.nativeEvent.offsetX || (e.nativeEvent.changedTouches[0].pageX * SCALE_FACTOR - offsetLeft));
        const dropY = (e.nativeEvent.offsetY || (e.nativeEvent.changedTouches[0].pageY - e.nativeEvent.changedTouches[0].target.offsetParent.offsetParent.offsetTop) * SCALE_FACTOR);

        if (selectedImage !== null && !mouseDownPoint.clickedObj){
            if (Math.abs(mouseDownPoint.dropX - dropX) < 20 && Math.abs(mouseDownPoint.dropY - dropY) < 20){
                AddIcon(dropX, dropY, selectedImage.imageUrl);
            }
        }
    }

    const Undo = (e) =>{
        e.preventDefault();
        if (currState !== 0){
            let save = history[currState - 1];
            currState = currState - 1
            canvas.loadFromJSON(save, ()=>{
                canvas.preserveObjectStacking = true;
                canvas.renderAll();
                canvas.getObjects().forEach((item, i) => {
                    item.set({
                        cornerSize: 10 * SCALE_FACTOR,
                        borderColor: 'black',
                        borderWidth: 20 * SCALE_FACTOR,
                        cornerColor: 'black',
                        transparentCorners: false
                    });
                });
            });
        }
    }

    const Redo = (e) => {
        e.preventDefault();
        if (currState < history.length - 1){
            let save = history[currState + 1];
            currState = currState + 1;
            canvas.loadFromJSON(save, ()=>{
                canvas.preserveObjectStacking = true;
                canvas.renderAll();
                canvas.getObjects().forEach((item, i) => {
                    item.set({
                        cornerSize: 10 * SCALE_FACTOR,
                        borderColor: 'black',
                        borderWidth: 20 * SCALE_FACTOR,
                        cornerColor: 'black',
                        transparentCorners: false
                    });
                });
            });
        }
    }

    const ResetAllSelecting = () => {
        setColor("#ffffff");
        setSelectedImage(null);
        canvas.discardActiveObject();
        canvas.renderAll();
    }

    const CopyAndPaste = () => {
        let activeObject = canvas.getActiveObject();

        if (activeObject != null){
            activeObject.clone((clonedObj) => {
                canvas.discardActiveObject();
                clonedObj.set({
                    left: clonedObj.left + 10,
                    top: clonedObj.top + 10,
                    evented: true,
                    cornerSize: 10 * SCALE_FACTOR,
                    borderColor: 'black',
                    borderWidth: 20 * SCALE_FACTOR,
                    cornerColor: 'black',
                    transparentCorners: false
                });
                if (clonedObj.type === 'activeSelection') {
                    // active selection needs a reference to the canvas.
                    clonedObj.canvas = canvas;
                    clonedObj.forEachObject((obj) => {
                        obj.set({
                            cornerSize: 10 * SCALE_FACTOR,
                            borderColor: 'black',
                            borderWidth: 20 * SCALE_FACTOR,
                            cornerColor: 'black',
                            transparentCorners: false
                        });
                        canvas.add(obj);
                    });
                    // this should solve the unselectability
                    clonedObj.setCoords();
                } else {
                    canvas.add(clonedObj);
                }
                clonedObj.top += 10;
                clonedObj.left += 10;
                canvas.setActiveObject(clonedObj);
                canvas.requestRenderAll();
            });
            Record();
        }
    }

    const Copy = ()=>{
        let activeObject = canvas.getActiveObject();
        if (activeObject != null){
            activeObject.clone((cloned) => {
                clipboard = cloned;
            });
        }
    }

    const Paste = ()=>{
        if (clipboard != null){
            clipboard.clone((clonedObj) => {
                canvas.discardActiveObject();
                clonedObj.set({
                    left: clonedObj.left + 10,
                    top: clonedObj.top + 10,
                    evented: true,
                    cornerSize: 10 * SCALE_FACTOR,
                    borderColor: 'black',
                    borderWidth: 20 * SCALE_FACTOR,
                    cornerColor: 'black',
                    transparentCorners: false
                });
                if (clonedObj.type === 'activeSelection') {
                    // active selection needs a reference to the canvas.
                    clonedObj.canvas = canvas;
                    clonedObj.forEachObject((obj) => {
                        obj.set({
                            cornerSize: 10 * SCALE_FACTOR,
                            borderColor: 'black',
                            borderWidth: 20 * SCALE_FACTOR,
                            cornerColor: 'black',
                            transparentCorners: false
                        });
                        canvas.add(obj);
                    });
                    // this should solve the unselectability
                    clonedObj.setCoords();
                } else {
                    canvas.add(clonedObj);
                }
                clipboard.top += 10;
                clipboard.left += 10;
                canvas.setActiveObject(clonedObj);
                canvas.requestRenderAll();
            });
            Record();
        }
    }

    const Delete = () =>{
        let activeObjects = canvas.getActiveObjects();
        activeObjects.forEach((obj) =>{
            canvas.remove(obj);
        });
        canvas.discardActiveObject().renderAll();
        Record();
    }


    const AutoUpload = () => {
        setIsUploading(true);
        const [height, width] = ComputeCanvasDimension(toolBoxOpen);
        var canvas_json = canvas.toJSON();
        canvas_json.width = width;
        canvas_json.height = height;
        var data = {
            "login_key": roomKey,
            "name": userName,
            "url":JSON.stringify(canvas_json)
        }

        axios.post(`${SERVER_URL}/api/canvas`, data).then(res => {
            setSnackBarMessage("Auto-uploaded!");
            setSnackBarOpen(true);
            setIsUploading(false);
        }, err => {
            setSnackBarMessage("Auto-uploaded Failed!");
            setSnackBarOpen(true);
            setIsUploading(false);
            if (err.response.status === 401){
                return err;
            }
            
        });
    }


    const Upload = () => {
        setIsUploading(true);
        const [height, width] = ComputeCanvasDimension(toolBoxOpen);
        var canvas_json = canvas.toJSON();
        canvas_json.width = width;
        canvas_json.height = height;
        var data = {
            "login_key": roomKey,
            "name": userName,
            "url":JSON.stringify(canvas_json)
        }

        axios.post(`${SERVER_URL}/api/canvas`, data).then(res => {
            setSnackBarMessage("Uploaded!");
            setSnackBarOpen(true);
            setIsUploading(false);
        }, err => {
            setSnackBarMessage("Upload Failed!");
            setSnackBarOpen(true);
            setIsUploading(false);
            if (err.response.status === 401){
                return err;
            }
        });
    }

    const MoveUp = () => {
        let activeObject = canvas.getActiveObject();
        if (activeObject != null){
            if (activeObject.type !== 'activeSelection') {
                activeObject.bringForward();
            }
            Record();
        }
    }

    const MoveDown = () => {
        let activeObject = canvas.getActiveObject();
        if (activeObject != null){
            if (activeObject.type !== 'activeSelection') {
                activeObject.sendBackwards();
            }
            Record();
        }
    }

    const MoveFront = () => {
        let activeObject = canvas.getActiveObject();
        if (activeObject != null){
            if (activeObject.type !== 'activeSelection') {
                activeObject.bringToFront();
            }
            Record();
        }
    }

    const MoveBack = () => {
        let activeObject = canvas.getActiveObject();
        if (activeObject != null){
            if (activeObject.type !== 'activeSelection') {
                activeObject.sendToBack();
            }
            Record();
        }
    }

    const ExportPNG = (props) => () => {
        const {
            transparentBg = false
        } = props;

        setSelectedImage(null);
        canvas.discardActiveObject();
        canvas.renderAll();

        if (transparentBg){
            canvas.setBackgroundColor("#ffffff00");
        }

        setSnackBarMessage("Loading");
        setSnackBarOpen(true);
        downloadPNG(canvas, userName, callbackForDownload);

        if (transparentBg){
            canvas.setBackgroundColor(backgroundColor);
        }
    }

    const ExportPDF = (props) => () =>{

        const {
            transparentBg = false
        } = props;

        setSelectedImage(null);
        canvas.discardActiveObject();
        canvas.renderAll();

        if (transparentBg){
            canvas.setBackgroundColor("#ffffff");
        }

        setSnackBarMessage("Loading");
        setSnackBarOpen(true);
        downloadPDF(canvas, userName, callbackForDownload);

        if (transparentBg){
            canvas.setBackgroundColor(backgroundColor);
        }
    }

    const callbackForDownload = (msg) => {
        setSnackBarOpen(false);
        setSnackBarMessage(msg);
        setSnackBarOpen(true);
    }

    const SubPresetSelected = (imageBaseName, imageUrl) => {
        setColor("#ffffff");
        setPrintMode(true);
        canvas.defaultCursor = 'auto';
        setSelectedImage({
            imageBaseName: imageBaseName,
            imageUrl: imageUrl
        });
    }

    const Selected = (imageBaseName, imageUrl) => {
        setColor("#ffffff");
        setPrintMode(true);
        canvas.defaultCursor = 'auto';
        if (selectedImage === null){
            setSelectedImage({
                imageBaseName: imageBaseName,
                imageUrl: imageUrl
            });
        }else{
            if (imageBaseName === selectedImage.imageBaseName){
                setSelectedImage(null);
            }else{
                setSelectedImage({
                    imageBaseName: imageBaseName,
                    imageUrl: imageUrl
                });
            }
        }
        
    }

    const handleBackgroundChangeColor = (c) => {
        setBackgroundColor(c.hex);
        canvas.setBackgroundColor(c.hex);
        canvas.renderAll();
        Record();
    }

    const handleChangeColor = (c) =>{
        setColor(c.hex);
        let activeObjects = canvas.getActiveObjects();
        activeObjects.forEach((activeObject) => {
            setColorFilter(activeObject);
        })
        
        canvas.renderAll();
        Record();
    }

    const setColorFilter = (Obj) => {
        if (1||Obj.needColorPicker){
            if (Obj.text){ // text
                Obj.set({fill: color});
            }else{ //image
                Obj.filters = [new fabric.Image.filters.BlendColor()];

                Obj.filters[0].color = color;
                Obj.filters[0].mode = 'multiply';
                Obj.filters[0].alpha = 1;

                Obj.applyFilters();
            }
        }   
    }

    const InfoClickHandler = async(e) => {
        e.preventDefault();
        if (userName === ''){
            setUserNameError('You must enter a name to start!');
        }else{
            setInfoBoxButton(false);
            const res = await axios.get(`${SERVER_URL}/api/canvas/${roomKey}/${userName}`);
            if (res.data === ''){
                initCanvas();
                Upload();
                setBackDropOpen(false);
            }else{
                var canvasObj = JSON.parse(res.data.url);
                GlobalRatio = parseFloat(canvasObj.width / canvasObj.height);
                loadCanvas(canvasObj);
                setBackDropOpen(false);
            }
        }
    }

    const handleSnackbarClose = (e, reason) => {
        if (reason === 'clickaway'){
            return;
        }
        setSnackBarOpen(false);
    }

    const handleSizeRadioChange = (e) => {
        setCanvasSize(parseFloat(e.target.value));
        GlobalRatio = e.target.value;
    }

    const ToggleMode = (e) => {
        setPrintMode(!printMode);
        canvas.defaultCursor = !printMode ? 'auto' : `url("${PUBLIC_URL}/baseline_edit_black_24dp.png"), auto`
    }

    const backgroundColorPickerOpen = Boolean(anchorBackgroundPicker);
    const backgroundColorPickerId = backgroundColorPickerOpen ? 'background-picker-popover' : undefined;

    const colorPickerOpen = Boolean(anchorColorPicker);
    const colorPickerId = colorPickerOpen ? 'color-picker-popover' : undefined;

    const popupState = usePopupState({ variant: 'popper', popupId: 'hintsPopper' })

    return (
        <div className={classes.root}>
            <AppBar 
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: toolBoxOpen,
                })}
            >
                <Toolbar>
                    <img src={logo} alt="mushroomX-logo" className={classes.logo}/>
                    <div className={classes.span}>
                        
                    </div>
                    <Typography color="TextPrimary" className={classes.name}>
                    {t('canvas_page.expiry_date')} {expireAt}
                    </Typography>
                    <div className={classes.langSwitch}>
                        <SwitchLanguageButton/>
                    </div>
                    <Typography color="TextPrimary" className={classes.name}>
                        {t('canvas_page.hi,')} {userName}
                    </Typography>
                    
                    <Button color="inherit" onClick={Logout} endIcon={<ExitToAppOutlinedIcon />} className={clsx(classes.logout)}>{t('canvas_page.logout')}</Button>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleToolBoxOpen}
                        edge="start"
                        className={clsx(toolBoxOpen && classes.hide)}
                    >
                        <MenuIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            
            <main
                className={clsx(classes.content, {
                    [classes.contentShift]: toolBoxOpen,
                })}
            >
                <div className={classes.drawerHeader} id="main-header-block"/>
                <Box 
                    id="canvas-viewport" 
                    className={classes.canvasViewport}
                    onDrop={Drop} 
                    onDragOver={(e) => {
                        e.preventDefault();
                    }} 
                    onMouseDown={Canvas_Mouse_Down} 
                    onMouseUp={Canvas_Mouse_Up}
                    onTouchStart={Canvas_Mouse_Down}
                    onTouchEnd={Canvas_Mouse_Up}
                >
                    <canvas style={{ border: 'solid 1px #555', boxSizing: 'border-box' }} id="main-canvas" />
                </Box>
            </main>
            
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="right"
                open={toolBoxOpen}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                
                <div className={classes.drawerHeader}>
                    <IconButton onClick={handleToolBoxClose} className={classes.logout}>
                        {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                    <div>
                        <IconButton  {...bindToggle(popupState)}  className={classes.logout}>
                            <ErrorOutlineIcon />
                        </IconButton>
                        <Popper {...bindPopper(popupState)} transition className={classes.popper}>
                            {({ TransitionProps }) => (
                            <Fade {...TransitionProps} timeout={350}>
                                <Paper className={classes.popperPaper}>
                                <Typography className={classes.typography}>
                                    <h3>{t('canvas_page.shortcut_list.headline')}</h3>
                                    <p>{t('canvas_page.shortcut_list.undo')}: Ctrl + Z</p>
                                    <p>{t('canvas_page.shortcut_list.redo')}: Ctrl + Shift + Z</p>
                                    <p>{t('canvas_page.shortcut_list.copy')}: Ctrl + C</p>
                                    <p>{t('canvas_page.shortcut_list.paste')}: Ctrl + V</p>
                                    <p>{t('canvas_page.shortcut_list.delete')}: Delete/Backspace</p>
                                    <p>{t('canvas_page.shortcut_list.bring_forward')}: Up ↑</p>
                                    <p>{t('canvas_page.shortcut_list.send_backward')}: Down ↓</p>
                                    <p>{t('canvas_page.shortcut_list.reset_cursor')}: ESC</p>
                                </Typography>
                                </Paper>
                            </Fade>
                            )}
                        </Popper>
                        <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            className={classes.buttonBig}
                            endIcon={<BackupOutlinedIcon />}
                            onClick={Upload}
                        >
                            {t('canvas_page.upload')}
                        </Button>

                        {
                            !printMode
                            ? 
                            <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            className={classes.buttonBig}
                            endIcon={<FingerprintIcon />}
                            onClick={ToggleMode}
                            >
                                {t('canvas_page.print_mode')}
                            </Button> 
                            : 
                            <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            className={classes.buttonBig}
                            endIcon={<EditIcon />}
                            onClick={ToggleMode}
                            >
                                {t('canvas_page.edit_mode')}
                            </Button>
                        }
                    </div>
                </div>

                
                <Divider />
                <Box  className={classes.box}>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                    <Button
                        fullWidth
                        size="small"
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        endIcon={<FileCopyOutlinedIcon />}
                        onClick={CopyAndPaste}
                    >
                        {t('canvas_page.duplicate')}
                    </Button>
                    </Grid>

                    <Grid item xs={6}>
                    <Button
                        fullWidth
                        size="small"
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        endIcon={<DeleteOutlineOutlinedIcon />}
                        onClick={Delete}
                    >
                        {t('canvas_page.delete')}
                    </Button>
                    </Grid>
                    <Grid item xs={6}>
                    <Button
                        fullWidth
                        size="small"
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        endIcon={<UndoIcon />}
                        onClick={Undo}
                    >
                        {t('canvas_page.undo')}
                    </Button>
                    </Grid>

                    <Grid item xs={6}>
                    <Button
                        fullWidth
                        size="small"
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        endIcon={<RedoIcon />}
                        onClick={Redo}
                    >
                        {t('canvas_page.redo')}
                    </Button>
                    </Grid>
                    <Grid item xs={6}>
                    <Button
                        fullWidth
                        size="small"
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        endIcon={<ArrowUpwardIcon />}
                        onClick={MoveUp}
                    >
                        {t('canvas_page.bring_forward')}
                    </Button>
                    </Grid>

                    <Grid item xs={6}>
                    <Button
                        fullWidth
                        size="small"
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        endIcon={<ArrowDownwardIcon />}
                        onClick={MoveDown}
                    >
                        {t('canvas_page.send_backward')}
                    </Button>
                    </Grid>
                    <Grid item xs={6}>
                    <Button
                        fullWidth
                        size="small"
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        endIcon={<FlipToFrontIcon />}
                        onClick={MoveFront}
                    >
                        {t('canvas_page.send_to_front')}
                    </Button>
                    </Grid>

                    <Grid item xs={6}>
                    <Button
                        fullWidth
                        size="small"
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        endIcon={<FlipToBackIcon/>}
                        onClick={MoveBack}
                    >
                        {t('canvas_page.send_to_back')}
                    </Button>
                    </Grid>

    
                    <Grid item xs={6}>
                    <Button
                        fullWidth
                        size="small"
                        variant="contained"
                        color="primary"
                        className={classes.buttonBig}
                        endIcon={<ColorizeIcon />}
                        aria-describedby={backgroundColorPickerId}
                        onClick={(e) => {
                            setAnchorBackgroundPicker(e.currentTarget);
                        }}
                    >
                        {t('canvas_page.background_color')}
                    </Button>
                    </Grid>
                    
                    <Grid item xs={6}>
                    <Popover
                        id={backgroundColorPickerId}
                        open={backgroundColorPickerOpen}
                        anchorEl={anchorBackgroundPicker}
                        onClose={() => {
                            setAnchorBackgroundPicker(null);
                        }}
                        anchorOrigin={{
                            vertical: 'center',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'center',
                            horizontal: 'left',
                        }}
                    >
                        <ChromePicker 
                            onChange={handleBackgroundChangeColor}
                            color = {backgroundColor}
                        />
                    </Popover>
                    <Button
                        fullWidth
                        size="small"
                        variant="contained"
                        color="primary"
                        className={classes.buttonBig}
                        endIcon={<ColorLensOutlinedIcon />}
                        aria-describedby={colorPickerId}
                        onClick={(e) => {
                            setAnchorColorPicker(e.currentTarget);
                        }}
                    >
                        {t('canvas_page.color_picker')}
                    </Button>
                    <Popover
                        id={colorPickerId}
                        open={colorPickerOpen}
                        anchorEl={anchorColorPicker}
                        onClose={() => {
                            setAnchorColorPicker(null);
                        }}
                        anchorOrigin={{
                            vertical: 'center',
                            horizontal: 'center',
                        }} 
                        transformOrigin={{
                            vertical: 'center',
                            horizontal: 'left',
                        }}
                    >
                        <SketchPicker 
                            onChange={handleChangeColor}
                            color = {color}
                            onAccept={()=> {setAnchorColorPicker(false)}}
                            onCancel={()=> {setAnchorColorPicker(false)}}
                        />
                    </Popover>
                    {/*<Button
                        fullWidth
                        size="small"
                        variant="contained"
                        color="primary"
                        className={classes.buttonBig}
                        endIcon={<SelectAllIcon />}
                        //onClick={Upload}
                    >
                        Select
                    </Button>*/}
                    </Grid>
                </Grid>
                </Box>
                <Divider />
                <Divider />
                <Box>
                    <Box>
                        <AppBar position="static" className={clsx(classes.title)}>
                            <Typography variant="h5" color="TextPrimary" gutterBottom align="center" className={classes.title}>
                            {t('canvas_page.pre_set_image')}
                            </Typography>
                        </AppBar>
                        <Grid container spacing={0}>
                            {   
                                Object.keys(presetImage).sort((a, b) => parseInt(a) - parseInt(b)).map((imageBaseName, index)=>{
                                    const imageSet = presetImage[imageBaseName];
                                    
                                    return (
                                        <Grid item xs={3} key={imageBaseName} className={clsx(classes.menuImageWrapper, {
                                            [classes.imageSelected]: !(selectedImage ==null || selectedImage.imageBaseName !== imageBaseName),
                                        })}>
                                            <PresetImage imageSet={imageSet} imageBaseName={imageBaseName} Drag={Drag} Selected={SubPresetSelected}/>
                                        </Grid>
                                    )      
                                })
                                
                            }
                        </Grid>
                    </Box>       
                    {
                        simple ? "" :
                        <Box>
                            <AppBar position="static" className={clsx(classes.title)}>
                                <Typography variant="h5" color="TextPrimary" gutterBottom align="center" className={classes.title}>
                                {t('canvas_page.custom_image')}
                                </Typography>
                            </AppBar>
                            <Grid container spacing={0}>
                                {   
                                    (uploadImage || [] ).map((image, index) => (
                                        <Grid item xs={3} key={index} className={clsx(classes.menuImageWrapper, {
                                            [classes.imageSelected]: !(selectedImage ==null || selectedImage.imageBaseName !== image),
                                        })}>
                                            <UploadedImage image={image} onDragStart={Drag} onClick={Selected}/>
                                        </Grid>
                                    ))
                                }
                            </Grid>
                        </Box>
                    }

                                
                </Box>
                <Divider />
                <Box className={classes.box}>
                    
                    <Grid container spacing={1}>
                    <Grid item xs={10}>
                    <form id="text-form" noValidate autoComplete="off" onSubmit={AddText}>
                        <TextField label={t('canvas_page.add_text')} variant="outlined" value={text} onChange={(e) => setText(e.target.value)} fullWidth />
                    </form>
                    </Grid>
                    <Grid item xs={2}>
                        <IconButton 
                            size="large" 
                            aria-label="close" 
                            color="inherit" 
                            onClick={AddText}
                        >
                        <AddBoxOutlinedIcon />
                        </IconButton>
                    </Grid>
                    </Grid>
                </Box>
                <Box className={classes.box}>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <Button
                                fullWidth
                                size="small"
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                endIcon={<ImageOutlinedIcon />}
                                onClick={ExportPNG({transparentBg: false})}
                            >
                                {t('canvas_page.export_as_png')}
                            </Button>
                        </Grid>

                        <Grid item xs={6}>
                            <Button
                                fullWidth
                                size="small"
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                endIcon={<PictureAsPdfOutlinedIcon />}
                                onClick={ExportPDF({transparentBg: false})}
                            >
                                {t('canvas_page.export_as_pdf')}
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <Button
                            fullWidth
                            size="small"
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            endIcon={<ImageOutlinedIcon />}
                            onClick={ExportPNG({
                                transparentBg: true
                            })}
                        >
                            {t('canvas_page.export_as_png_with_transparent_background')}
                        </Button>
                    </Grid>

                    <Grid item xs={6}>
                        <Button
                            fullWidth
                            size="small"
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            endIcon={<PictureAsPdfOutlinedIcon />}
                            onClick={ExportPDF({
                                transparentBg: true
                            })}
                        >
                            {t('canvas_page.export_as_pdf_with_transparent_background')}
                        </Button>
                    </Grid>
                </Grid>
                </Box>
            </Drawer>
            

            <Backdrop className={classes.backdrop} open={backDropOpen}>
            <Dialog
                open={backDropOpen}
                aria-labelledby="alert-dialog-title"
                className={classes.backdropDialog}
                fullWidth={true}
            >
                <DialogTitle id="alert-dialog-title">{t('welcome_dialog.welcome_to_x_spore_canvas')}</DialogTitle>
                <DialogContent>
                <form noValidate autoComplete="off" onSubmit={InfoClickHandler}>
                    <TextField 
                    label={t('welcome_dialog.please_enter_your_name')}
                    value={userName}
                    onChange={(e) => {setUserName(e.target.value)}}
                    className={classes.backdropDialogNameField}
                    error={userNameError !== ''}
                    helperText={userNameError}
                    />

                    <FormControl component="fieldset" className={classes.radioForm}>
                    <FormLabel component="legend">{t('welcome_dialog.canvas_size')}</FormLabel>
                    <RadioGroup aria-label="gender" name="size" value={canvasSize} onChange={handleSizeRadioChange} >
                        <FormControlLabel value={3580/2480} control={<Radio />} label={t('welcome_dialog.a4')} />
                        <FormControlLabel value={1} control={<Radio />} label={t('welcome_dialog.square')} />
                        <FormControlLabel value={2732/2480} control={<Radio />} label={t('welcome_dialog.ipad')} />
                    </RadioGroup>
                    </FormControl>
                </form>
                </DialogContent>
                <DialogActions>
                <IconButton 
                onClick={InfoClickHandler}
                disabled={!infoBoxButton}
                >
                    <SendIcon/>
                </IconButton>
                </DialogActions>
            </Dialog>
            </Backdrop>

            <Backdrop className={classes.backdrop} open={isUploading}>
                <CircularProgress color="inherit"/>
            </Backdrop>

            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={snackBarOpen}
                autoHideDuration={2000}
                onClose={handleSnackbarClose}
                message={snackBarMessage}
                action={
                    <React.Fragment>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackbarClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
            />
        </div>
    );
}

export default CanvasPage;